@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

@mixin columnSize {
  grid-template-columns: 6% 6% 7% 8% 8% 16% 8% 12% 8% 9% 9% 3%;
}

.tableGrid {
  position: relative;
}

.tableMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .tableGrid {
    display: none;
  }

  .tableMobile {
    display: block;
    max-height: calc(100vh - variable.$nav_sp_height - 164px);
    overflow: auto;
    padding-bottom: 16px;

    &.expand {
      max-height: calc(100vh - variable.$nav_sp_height - 66px);
    }
  }
}

.emptyBills {
  font-size: 3.2rem;
  color: color.$red-color;
  position: absolute;
  margin: 48px;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.smallCol {
  min-width: 50px !important;
}

.headerGrid {
  display: grid;
  background-color: color.$blue-color;
  color: color.$white-color;
  @include columnSize();
}

.heading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: variable.$table_header_height;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
  border: 1px solid color.$black-color;
  user-select: none;
  position: relative;

  &.error::before {
    @include error.arrowErrorBox;
    border-color: transparent transparent color.$near-white-color transparent;
    left: 76%;
    top: 88%;
  }

  &.error::after {
    @include error.errorBox;
    left: 70%;
    top: 110%;
    background-color: color.$near-white-color;
    text-align: left;
    color: color.$black-color;
    text-transform: initial;
    font-size: 1.6rem;
    word-wrap: break-word;
    min-width: 240px;
  }

  .sortIcon {
    margin-left: 2px;
  }
}

.productImg {
  width: 100%;
  max-height: 100%;
  object-fit: contain;

  &:hover {
    position: relative;
    top: 0;
    right: 0;
    transform: scale(300%);
    transform-origin: top right;
  }
}

.tableBody {
  display: grid;
  grid-template-columns: auto;
  min-height: 100px;
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.tableRowGrid {
  font-size: 1.6rem;
  width: 100%;
  display: grid;

  &.hightlight {
    color: color.$red-color;
    font-weight: 700;
  }
  @include columnSize();
}

.tableBodyRowContent {
  border: 1px solid color.$black-color;
  padding: 5px;
  height: 150px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: color.$white-color;

  & p {
    margin-bottom: 4px;
    text-align: center;

    span {
      display: block;
    }

    span:first-child {
      text-transform: initial;
    }
  }

  & input {
    outline: none;
  }
}

@media screen and (max-width: 768px) {
  @mixin columnSize {
    grid-template-columns: 10% 20% 25% 45%;
  }

  .heading {
    font-size: 1.2rem;
  }
  
  .tableRowGrid,
  .headerGrid {
    @include columnSize();

    .heading,
    .tableBodyRowContent {
      &:not(:nth-child(2), :nth-child(4), :nth-child(5), :nth-child(11)) {
        display: none;
      }
    }
  }

  .tableBodyRowContent {
    font-size: 1.4rem;

    &.zoom {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .emptyBills {
    font-size: 1.6rem;
    margin: 0;
    top: calc(48% + 40px);
  }

  .productImg:hover {
    transform: unset;
  }

  .tableBody {
    max-height: calc(100vh - variable.$nav_sp_height - 248px);
  }
}
