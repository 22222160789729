@use '@assets/scss/color';

.phoneBox {
  background-color: color.$box-color;
  color: color.$white-color;
}

.inputWrapper {
  & input {
    padding: 6px 12px;
  }
}
.selectWrapper {
  & :global(.MuiSelect-select) {
    padding: 6px 12px;
  }
}

