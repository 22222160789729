.productImg {
  width: 100%;
  max-height: 100%;
  object-fit: contain;

  &:hover {
    position: relative;
    top: 0;
    right: 0;
    transform: scale(300%);
    transform-origin: top right;
  }
}

