@use '@assets/scss/color';

.modalWrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1301;

    &.hide {
        display: none;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: color.$overlay-color;
}

.modalBody {
    max-width: 100%;
    width: 400px;
    background-color: color.$white-color;
    margin: auto;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;

    .heading {
        background-color: color.$blue-color;
        height: 60px;
        padding: 10px;

        img {
            height: 40px;
            object-fit: contain;
        }

        h3 {
            font-size: 2rem;
            padding: 0;
            margin: 0;
            line-height: 40px;
            margin-left: 20px;
            color: color.$white-color;
            text-transform: uppercase;
            display: inline-block;
        }
    }

    .body {
        padding: 16px;

        .bodyTitle {
            font-size: 2rem;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;

            i {
                font-size: 3.2rem;
                margin-right: 16px;
                color: color.$blue-color;
            }

            span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .bodyContent {
            font-size: 1.6rem;
            line-height: 1.6;
            font-weight: 400;
            margin-left: 48px;
            font-weight: 500;

            p {
                margin-bottom: 0;
            }
        }

        .bodyFooter {
            margin-top: 16px;
            display: flex;
            justify-content: space-evenly;

            .btn {
                font-size: 1.6rem;
                min-width: 120px;
                padding: 8px 16px;
                border-radius: 4px;
                border: none;
                outline: none;
                color: color.$white-color;
                background-color: color.$blue-color;
                text-transform: uppercase;
                font-weight: 500;

                &:hover {
                    opacity: 0.8;
                }

                &.focus {
                    background-color: color.$btn-focus-color;
                }
            }
        }
    }
}
