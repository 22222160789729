@use '@assets/scss/color';
@use '@assets/scss/variable';

.containerClassName {
  width: 200px;
  border-radius: 4px;

  .selectClassName {
    width: 100%;
    height: 34px;
    padding: 4px;
    border: 1px solid color.$border-color;
    border-radius: 4px;
  }
}

button.toggleFilterButton {
  display: none;
}

@media screen and (max-width: 768px) {
  button.toggleFilterButton {
    display: inline-flex;
    background-color: color.$primary;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  .hideOnPhone {
    display: none !important;
  }
  
  div.filterContainer {
    position: absolute;
    top: calc(100% - 16px);
    background-color: white;
    z-index: 2;
    left: 16px;
    right: 16px;
    align-items: center;
    margin-left: 0;
    box-shadow: 2px 2px 16px color.$shadow;
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding: 0;
    visibility: hidden;
  }

  div.filterContainer.show {
    display: flex;
    visibility: visible;
    height: auto;
    padding: 16px;
  }
}

.billCount,
.billCountSP {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: underline;

  .billCountContent {
    display: inline-block;
    font-size: 2rem;
    margin-left: 12px;
  }
}

.billCountSP {
  display: none;
}

@media screen and (max-width: 768px) {
  .billCount {
    display: none;
  }
  .billCountSP {
    display: block;
    font-size: 1.4rem;
  }
}
